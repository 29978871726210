<template>
  <div>
    <NewMenu/>
    <div class="container mt-5 pt-5">
      <img src="../../assets/img/vertical-dots.svg" class="vertical-img-bg" alt="dots-bg-img">
      <section class="__section">
        <h1 class="mt-5">{{ data.title }}</h1>
        <form class="md needs-validation is-valid d-flex flex-column" @submit="send">
          <div class="form-group wet-form mb-3">
            <label class="form-label">{{ data.fields.email.text }}</label>
            <input v-model="Form.email" placeholder="Ingrese su Email" type="email"
                   @keydown.enter="send"
                   class="form-control" required>
          </div>

          <div class="form-group wet-form mb-4 password">
            <label class="form-label">{{ data.fields.password.text }}</label>
            <input v-model="Form.password" :placeholder="data.fields.password.placeholder"
                   @keydown.enter="send"
                   :type="showPassword ? 'password' : 'text'"
                   class="form-control" required>
            <img
              :src="showPassword ? require('../../assets/icons/hide.png') : require('../../assets/icons/show.png')"
              class="icon" width="20px" alt="" @click="showPassword = !showPassword">
          </div>
          <div class="d-flex justify-content-between">
            <router-link :to="{path: '/password-recovery'}">
              <button class="btn-wt-outline green" type="button">
                {{ data.forgotPasswordText }}
              </button>
            </router-link>
            <router-link :to="{path: '/register'}">
              <button class="btn-wt-outline green">
                {{ data.registerButtonText }}
              </button>
            </router-link>
          </div>
          <button class="btn btn-success btn-lg mt-5" type="submit">
            {{ data.actionButtonText }}
          </button>
        </form>
      </section>
    </div>
  </div>
</template>
<script>
import NewMenu from "@/components/layout/NewMenu.vue";
import {login} from "../../helpers/auth";
import store from "../../store";

export default {
  components: {NewMenu},
  beforeDestroy() {

  },

  created() {

  },

  data() {
    return {
      showPassword: true,
      data: {
        title: "Iniciar sesión",
        forgotPasswordText: "Olvide mi contraseña",
        registerButtonText: "Registrarse",
        actionButtonText: 'Ingresar',
        fields: {
          email: {text: "Email de contacto", placeholder: "Ingrese un email de contacto",},
          password: {text: "Contraseña", placeholder: "Ingresar contraseña"},
        },
      },
      Form: {
        email: null,
        password: null
      }
    }
  },

  mounted() {
    this.fetchData();
  },
  watch: {
    "$store.state.sections": function () {
      this.fetchData()
    }
  },
  methods: {
    fetchData() {
      if (store.state.sections.login) {
        this.data = {...store.state.sections.login.components};
      }
    },
    send: async function (e) {
      e.preventDefault();
      const response = await login({...this.Form, language: this.$store.getters.getLanguage});
      if (response.status === 200 && response.data[0].rol === 'client') {
        await this.$router.push('/questionnaire')
      } else if (response.status === 200 && response.data[0].rol === 'admin') {
        await this.$router.push('/admin')
      }
    }
  },
}
</script>
<style lang="scss" scoped>

h1 {
  color: #23af4d;
  font-weight: bold;
  font-size: 3rem !important;
}

.password {
  position: relative;

  & .icon {
    cursor: pointer;
    position: absolute;
    top: 55%;
    right: 3%;
  }
}

@media (min-width: 1200px) {
  .__section {
    margin: 0 !important;

    form.md {
      max-width: 756px;
    }
  }

  .wet-module-container {
    justify-content: center;
  }
}
</style>
